<template>
  <v-container class="">
    <v-row
      v-if="!post.id"
      class="fill-height"
      align-content="center"
      justify="center"
      align="center"
    >
      <v-col
        v-if="!loading"
        cols="12"
      >
        <h2 class="primary--text text-center">
          {{ $t("Article non trouvé") }}
        </h2>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader
          type="image"
          height="250"
        />
        <v-skeleton-loader
          type="image"
          height="250"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader
          type="article"
          height="250"
        />
        <v-skeleton-loader
          type="article"
          height="250"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
      >
        <h1 class="mb-4 text-h5 text-md-h4 font-weight-bold">
          {{ post.title }}
        </h1>
        <div class="mb-4">
          <p
            class=""
            v-html="post.content"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'

  import { i18n } from '../../plugins/i18n'

  export default {
    components: {
    },

    data () {
      return {
        tab: null,
        loading: false,
        isQuantity: 0,
        post: {},
        breadcrumbs: [
          {
            text: i18n.t('Accueil'),
            disabled: false,
            to: '/',
          },
        ],

      }
    },
    computed: {
      ...mapGetters(['getPosts']),
      ...mapState({
        user: 'user',
      }),
      params () {
        return {
          path: this.$route.path,
        }
      },
    },
    watch: {
      params (val) {
        var item = this.getPosts.find(ele => ele.slug === this.$route.params.slug)
        if (item != null) {
          this.post = item
        }
        this.getPost()
      },
    },
    created () {
      // var item = this.getPosts.find(ele => ele.slug === this.$route.params.slug)
      /* if (item != null) {
        this.post = item
      } */
      this.getPost()
    },
    destroyed () {
      this.setValue({
        type: 'breadcrumbs',
        value: [],
      })
    },
    methods: {
      ...mapActions(['addCart', 'removeCart', 'fetchPost']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      getPost () {
        this.loading = true
        axios
          .get(this.$route.path)
          .then(response => {
            if (response.data && response.data.id) {
              this.post = response.data
              this.pushItem({ type: 'posts', field: 'slug', value: this.post.id, object: this.post })
              this.setBreadcrumbs([...this.breadcrumbs, {
                text: this.post.title,
                disabled: true,
                to: '/',
              }])
            }
          })
          .catch(error => console.log(error)).then(() => {
            this.loading = false
          })
      },

    },
  }
</script>
<style lang="scss" scoped>
    .post-slider {

        .post-slider-click {
            display: flex;
            justify-content: center;
            ::v-deep .slick-slide {
               width: 80px !important;
               margin-right: 16px;
               border: 1px solid rgb(218, 225, 231);
               border-radius: 5px;
            }
            ::v-deep .slick-current {
                border: 1px solid #D23F57;
                border-radius: 5px;
            }
        }
    }
</style>
